<template>
	<section class="promotions background-dark main-content align-center">
		<div class="row row-header align-center">
			<h2 class="subtitle">{{ subtitle }}</h2>
			<h2>{{ header }}</h2>
			<div v-parse-links v-html="content" />
		</div>
		<div class="row promotions-row flex-row slide-row align-left">
			<client-only>
				<div v-for="room in items.slice(0, 4)" :key="room.ID" class="columns column3 promo-wrap">
					<nuxt-link :to="localePath(`/${room.filename}`)">
						<picture>
							<source v-if="room.imageOverviewWebp" :srcset="room.imageOverviewWebp" type="image/webp" />
							<source :srcset="room.imageOverview" />
							<img loading="lazy" :src="room.imageOverview" :alt="room.imageOverviewAlt" />
						</picture>
					</nuxt-link>
					<div class="content">
						<h3>{{ room.header }}</h3>
						<nuxt-link :to="localePath(`/${room.filename}`)" class="promo-button">
							{{ defaults[locale].rooms.view }}
							<font-awesome-icon icon="fa-light fa-arrow-right" size="1x" />
						</nuxt-link>
					</div>
				</div>
			</client-only>
		</div>
	</section>
</template>

<script setup>
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchDefaults();

defineProps({
	items: { type: Array, default: () => [] },
	subtitle: { type: String, default: '' },
	header: { type: String, default: '' },
	content: { type: String, default: '' },
});
</script>

<style lang="scss" scoped>
.promotions-row {
	h3 {
		text-transform: uppercase;
	}

	.content {
		margin: 20px 0;
	}

	.promo-button {
		width: 100%;
		border-bottom: 1px solid #000;
		color: #000;
		text-transform: uppercase;
		font-family: var(--heading-font-family);
		font-size: 12px;
		line-height: 16px;
		padding: 8px 0;
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;

		svg {
			font-size: 16px;
			transform: rotate(315deg);
			transition: all 0.2s ease-in-out;
		}

		&:hover {
			svg {
				transform: rotate(360deg);
			}
		}
	}
}

.promo-wrap {
	img {
		opacity: 0.9;
		transition: all 0.3s ease-in-out;
	}

	&:hover {
		img {
			opacity: 1;
		}
	}
}
</style>
