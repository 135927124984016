<template>
	<page-container :page="page">
		<heading-image
			v-if="page.headingImage"
			:heading-image="{
				image: page.headingImage,
				imageWebp: page.headingImageWebp,
				imagePortrait: page.headingImagePortrait,
				imagePortraitWebp: page.headingImagePortraitWebp,
			}"
		>
			<template #heroContent>
				<div v-if="defaults[locale].homepage.headingTitle" class="hero-content">
					<span class="hero-title">
						{{ defaults[locale].homepage.headingTitle }}
					</span>
					<br />
					<span class="hero-subtitle">
						{{ defaults[locale].homepage.headingSubTitle }}
					</span>
				</div>
			</template>
		</heading-image>

		<main-content>
			<template #content>
				<div class="columns column6">
					<picture>
						<source
							v-if="defaults[locale].homepage.introImageWebp"
							:srcset="defaults[locale].homepage.introImageWebp"
							type="image/webp"
						/>
						<source :srcset="defaults[locale].homepage.introImage" />
						<img
							class="intro-image"
							:src="defaults[locale].homepage.introImage"
							:alt="defaults[locale].homepage.introImageAlt"
							loading="lazy"
						/>
					</picture>
				</div>
				<div class="columns column6">
					<div class="title-reverse-wrapper">
						<h1>{{ page.title || page.header }}</h1>
						<h2 class="subtitle">{{ page.subtitle }}</h2>
					</div>
					<div v-parse-links v-html="page.introductionContent" />
				</div>
			</template>
		</main-content>

		<booking-benefits
			:title="defaults[locale].homepage.sectionBenefitsTitle"
			:subtitle="defaults[locale].homepage.sectionBenefitsSubtitle"
			:benefits="defaults[locale].homepage.bookingBenefits"
			:image="{
				image: defaults[locale].homepage.sectionBenefitsImage,
				imageAlt: defaults[locale].homepage.sectionBenefitsImageAlt,
				imageWebp: defaults[locale].homepage.sectionBenefitsImageWebp,
			}"
		/>

		<promoblocks
			v-if="promoblocksData.length"
			:header="defaults[locale].homepage.sectionPromotionTitle"
			:subtitle="defaults[locale].homepage.sectionPromotionSubtitle"
			:promoblocks="promoblocksData"
			:amount="4"
		/>

		<contentblocks
			v-if="page && page.contentblocks && page.contentblocks.length"
			:contentblocks="page.contentblocks"
			:content-blocks-content="page.sectionContentblocksContent"
			:content-blocks-subtitle="page.sectionContentblocksTitle"
		/>

		<rooms-promotions
			:items="roomsData"
			:header="defaults[locale].rooms.sectionRoomsTitle"
			:subtitle="defaults[locale].rooms.subtitle"
			:content="defaults[locale].rooms.sectionRoomsContent"
		/>

		<newsletter
			v-if="page && page.showNewsletter"
			:header="defaults[locale].newsletter.title"
			:content="defaults[locale].newsletter.content"
			:button="defaults[locale].newsletter.button"
		/>
		<news-overview :news="news" />
		<frequently-asked-questions
			v-if="page && page.frequentlyAskedQuestion"
			:header="page.frequentlyAskedQuestion.header"
			:items="page.frequentlyAskedQuestion.faqitems"
		/>
		<pre-footer
			v-if="page && page.footerSection && page.footerSection.length"
			:footer-section="page.footerSection[0]"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

const { data: promoblocksData } = await useWebsiteFetch('promoblocks', {
	query: { language: locale.value },
	key: `${locale.value}/promoblocks`,
});

const { data: news } = await useWebsiteFetch('news', {
	query: { language: locale.value },
	key: `${locale.value}/news`,
});

const { data: roomsData } = await useWebsiteFetch('rooms', {
	query: { language: locale.value },
	key: `${locale.value}/rooms`,
});
</script>

<style lang="scss" scoped>
.hero-content {
	position: absolute;
	top: 45%;
	left: 0;
	right: 0;
	z-index: 3;
	text-align: center;
	padding: 0 20px;
	color: #fff;
	animation: fade-from-bottom 1.4s ease;
}

.hero-content span {
	color: var(--primary-background-color);
	font-weight: 400;
	font-size: 46px;
	letter-spacing: 1px;
	width: 100%;
	font-family: var(--body-font-family);
	display: inline-block;
	text-transform: uppercase;
	margin: 0 0 10px;
	max-width: 600px;
}

.hero-content .hero-subtitle {
	font-size: 20px;
	font-weight: 300;
	font-family: var(--body-font-family);
	text-transform: none;
	max-width: 800px;
}

.intro-image {
	width: 80%;
	margin: 0 20% 0 0;
	clip-path: polygon(50% 13%, 100% 30%, 100% 100%, 0% 100%, 0% 30%);
	overflow: hidden;
}

@supports (-webkit-mask-image: url('#mask')) or (mask-image: url('#mask')) {
	.intro-image {
		clip-path: none;
		mask-image: url('~/assets/images/intro-image-mask.png');
		mask-size: 100%;
	}
}

@media (max-width: 920px) {
	.intro-image {
		width: 100%;
		max-width: 420px;
		margin: 0 auto 40px;
	}
}

@media (max-width: 880px) {
	.hero-content .hero-title {
		font-size: 62px;
	}

	.hero-content .hero-subtitle {
		font-size: 24px;
	}
}

@media (max-width: 680px) {
	.hero-content .hero-title {
		font-size: 54px;
	}

	.hero-content .hero-subtitle {
		font-size: 22px;
	}
}

@media (max-width: 520px) {
	.hero-content .hero-title {
		font-size: 46px;
	}

	.hero-content .hero-subtitle {
		font-size: 20px;
	}
}
</style>
