<template>
	<section class="main-content news-section background-dark">
		<div class="row row-header align-center">
			<h2 class="subtitle">{{ defaults[locale].homepage.sectionNewsTitle }}</h2>
			<div v-parse-links v-html="defaults[locale].homepage.sectionNewsContent" />
		</div>
		<div class="row flex-row">
			<div class="flex-row column6">
				<div v-for="item in news.slice(0, 1)" :key="item.ID" class="block-item white">
					<nuxt-link :to="localePath(`/${item.filename}`)">
						<div class="block-item-content">
							<span class="event-date">
								{{ $d(new Date(item.date.date.split(' ')[0]), 'short', locale) }}
							</span>
							<h3>{{ item.title || item.header }}</h3>
							<div v-parse-links v-html="item.overviewContent" />
							<span class="promo-button">
								{{ $t('readMore') }} <font-awesome-icon icon="fa-light fa-arrow-right" size="1x" />
							</span>
						</div>
						<picture>
							<source v-if="item.overviewImageWebp" :srcset="item.overviewImageWebp" type="image/webp" />
							<source :srcset="item.overviewImage" />
							<img loading="lazy" :src="item.overviewImage" :alt="item.overviewImageAlt" />
						</picture>
					</nuxt-link>
				</div>
			</div>
			<div class="flex-row column6">
				<div v-for="item in news.slice(1, 5)" :key="item.ID" class="block-item white">
					<nuxt-link :to="localePath(`/${item.filename}`)">
						<div class="block-item-content">
							<span class="event-date">
								{{ $d(new Date(item.date.date.split(' ')[0]), 'short', locale) }}
							</span>
							<h3>{{ item.title || item.header }}</h3>
							<div v-parse-links v-html="item.overviewContent" />
							<span class="promo-button">
								{{ $t('readMore') }} <font-awesome-icon icon="fa-light fa-arrow-right" size="1x" />
							</span>
						</div>
						<picture>
							<source v-if="item.overviewImageWebp" :srcset="item.overviewImageWebp" type="image/webp" />
							<source :srcset="item.overviewImage" />
							<img loading="lazy" :src="item.overviewImage" :alt="item.overviewImageAlt" />
						</picture>
					</nuxt-link>
				</div>
			</div>
		</div>
	</section>
</template>

<script setup>
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchDefaults();

defineProps({
	news: { type: Array, default: () => [] },
});
</script>

<style lang="scss" scoped>
.contentblocks {
	background: var(--contentblock-background-color);
}

.event-date {
	color: #fff;
	font-size: 14px;
	margin: 0 0 10px;
	display: inline-block;
	width: 100%;
	letter-spacing: 2px;
}

.block-item a {
	color: #fff;
}

.promo-button {
	width: 100%;
	border-bottom: 1px solid #fff;
	color: #fff;
	text-transform: uppercase;
	font-family: var(--heading-font-family);
	font-size: 12px;
	line-height: 16px;
	padding: 8px 0;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;

	svg {
		font-size: 16px;
		transform: rotate(315deg);
		transition: all 0.2s ease-in-out;
	}

	&:hover {
		svg {
			transform: rotate(360deg);
		}
	}
}
</style>
